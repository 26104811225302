import { useLocation } from '@reach/router';
import Layout from 'common/layouts/Layout';
import { TrustPilot } from 'common/trustpilot/TrustPilot';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { AffiliateQuery } from 'graphqlTypes';
import { getUrlTrackingDetails } from 'helpers/getUtmDataFromUrl';
import { getAffiliateDetails } from 'page-components/affiliate/affiliateUtils';
import { Steps } from 'page-components/income';
import {
  Expert,
  Hero,
  Income,
  Plan,
  RightPlace,
  Spend,
} from 'page-components/index';
import { BottomCta, Featured } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface AffiliateProps {
  data: AffiliateQuery;
}

const Affiliate: FC<AffiliateProps> = ({ data }) => {
  const location = useLocation();
  const { source } = getUrlTrackingDetails(location.search);
  const affiliates = data.allContentfulAffiliatePartner.nodes;

  return (
    <Layout>
      <Navigation />
      <PageWrapper
        background="linear-gradient(
        180deg,
        #dbf0e7 0.34%,
        #ffe8d8 16.35%,
        #dcf1e8 40.86%,
        rgba(220, 241, 232, 0.2) 81.79%,
        #dbf0e7 100%
      )"
      >
        <Hero affiliate={getAffiliateDetails(source, affiliates)} />
        <Featured />
        <RightPlace />
        <Expert />
        <Plan />
        <Income />
        <Spend />
        <TrustPilot
          template="fullSizeCarousel"
          heading="We've helped more than 50,000 people just like you."
        />
        <Steps />
        <BottomCta
          heading="You're ready for retirement. Make sure your money is too."
          body="From greater peace of mind to ongoing care, we're ready to lend a hand."
        />
        <Footer />
      </PageWrapper>
    </Layout>
  );
};

export default Affiliate;

export const query = graphql`
  query Affiliate {
    allContentfulAffiliatePartner {
      nodes {
        ...AffiliatePartner
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Spend Retirement with More | Retirable"
    description="Retirable is the first-of-its-kind retirement planning, investing and spending solution with the ongoing care of a fiduciary advisor."
    imagePath="homepage-featured.png"
  />
);
