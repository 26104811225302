import { graphql } from 'gatsby';
import { AffiliatePartnerFragment } from 'graphqlTypes';

export interface AffiliateLanguage {
  affiliateName: string;
  partnershipTerms: string;
  displayLegalDetails: boolean;
}

export const AffiliatePartner = graphql`
  fragment AffiliatePartner on ContentfulAffiliatePartner {
    affiliateName
    utmSource
    partnershipTerms {
      partnershipTerms
    }
    displayLegalDetails
  }
`;

export const getAffiliateDetails = (
  source: string | null,
  affiliatePartners: AffiliatePartnerFragment[],
): AffiliateLanguage => {
  const partner = affiliatePartners.find((p) => p.utmSource === source);
  if (partner) {
    return {
      affiliateName: partner.affiliateName!,
      partnershipTerms: partner.partnershipTerms!.partnershipTerms!,
      displayLegalDetails: partner.displayLegalDetails!,
    };
  }

  return {
    affiliateName: 'The referring affiliate partner',
    partnershipTerms: '$500 if you enter into a paying relationship with us',
    displayLegalDetails: true,
  };
};
